import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import ContactSection from '../../components/ContactSection'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import NavigationLink from '../../components/NavigationLink'
import IconTeaser from '../../components/IconTeaser'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Community"
           description="BUSCHMAIS engagiert sich intensiv für die IT-Community und unterstützt die Fachkräfte- und Nachwuchsförderung in Sachsen. Erfahren Sie mehr über die Community und unser Engagement."
      />
      <BigTitle title="Community-Arbeit" className="mask-black" textInverted={true}
                imageRelativePath={'Titelbild_Unternehmen.jpg'} menuInverted={true} logoInverted={true}>Als
        Fördermitglied des JUG Saxony e. V. engagieren wir uns intensiv seit der ersten Stunde für den Verein und
        blicken auf die großartige Entwicklung einer starken Community.</BigTitle>


      <DefaultSection>
        <Article>
          <h1>An Morgen denken</h1>
          <p>Als Fördermitglied engagieren wir uns intensiv seit der ersten Stunde für den <NavigationLink
            to={'https://jugsaxony.org'} ariaLabel={'Website des JUG Saxony e.V.'} asTextLink>JUG Saxony e.
            V.</NavigationLink> und blicken
            auf die großartige Entwicklung einer starken Community. Diese schafft mit monatlichen
            Abendveranstaltungen,
            welche in unserem Team sehr gern besucht werden, nicht nur Raum für das Weiterdenken im sächsischen Raum,
            sondern darf auch stolz auf die Etablierung einer renommierten IT-Konferenz, dem <NavigationLink
              to={'https://jugsaxony.org/day'} ariaLabel={'JUG Saxony Day Website'} asTextLink> JUG
              Saxony Day</NavigationLink>, sein.
            Diese einzigartige und moderne Plattform lockt nationale und internationale Expertinnen und Experten der
            Branche,
            und bietet mit brandaktuellen Themen rund um Softwareentwicklung tollen Input für unsere tägliche Arbeit.
            Gern sind unsere Kollegen auch als Speaker dabei und vermitteln unser Fachwissen an das interessierte
            Publikum.
            Unter anderem dürfen wir regelmäßig die jährliche Weihnachtsveranstaltung des JUG Saxony thematisch
            ausgestalten.</p>
          <p>
            Als IT-Consultants verstehen wir uns als innovative Triebkraft und haben den Anspruch den aktuellen
            Entwicklungen
            in der Branche stets einen Schritt voraus zu sein. Der regelmäßige Austausch ist dafür unerlässlich.
            Die JUG Saxony bildet den perfekten Rahmen, um sich zu vernetzen und Erfahrungen auszutauschen.
            Aus Arbeitgeber-Sicht sind die Konferenzen natürlich auch eine tolle Plattform um sich zu präsentieren und
            sowohl
            erfahrenen Entwicklern wie auch dem Nachwuchs die Chance zu geben, BUSCHMAIS näher kennenzulernen.
            Zur Nachwuchsförderung ist auch das <NavigationLink to={'https://jugsaxony.org/camp'}
                                                                ariaLabel={'JUG Saxony Camp Website'} asTextLink>JUG
            Saxony Camp</NavigationLink> - die exklusive Konferenz für Studierende, die passende Anlaufstelle.</p>
          <p>
            Wir sind gespannt, welche Highlights der Verein in den kommenden Jahren auf die Beine stellen wird und
            sind
            voller Motivation, unseren Beitrag zu einem erfolgreichen Gelingen und einer Verbreiterung des JUG Saxony
            zu
            leisten.</p>

        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'Logo-Foerdermitglied_gruen.png'} iconImageAlt="Logo des JUG Saxony e. V.">
          <p>Im Jahre 2008 haben IT-Enthusiasten den Wunsch nach einem regelmäßigen Austausch unter Gleichgesinnten erfüllt und mit der ersten Veranstaltung am 3. April den Grundstein für den späteren JUG Saxony e. V. gelegt.

            Innerhalb kürzester Zeit hat sich die Community zu einem festen Bestandteil der Softwarebranche auch über Sachsens Landesgrenzen hinaus entwickelt.

            Wir stehen für Wissenstransfer und -austausch, für die Vernetzung von Wissenschaft und Wirtschaft sowie für die Nachwuchsförderung innerhalb und außerhalb der IT-Community.
            Dank des engagierten Einsatzes von ehrenamtlichen Helfern und der Unterstützung durch zahlreiche etablierte IT-Unternehmen aus der Region ist der Verein aus Sachsens IT-Landschaft nicht mehr wegzudenken.
          </p>
        </IconTeaser>
      </DefaultSection>
      <ContactSection/>
    </React.Fragment>
  )
}

export default Page
